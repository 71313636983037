import jquery from 'jquery'
import Turbolinks from "turbolinks"
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
window.jQuery = jquery;
window.$ = jquery;
